<div class="container-fluid">
  <div id="panelGames" class="row">
    <div class="col-12"><h2>Schedule</h2></div>
  </div>
  <div class="row">
    <div *ngFor="let item of games" class="col-sm-6 col-md-4 col-lg-4 col-12 mb-4">
      <csbc-game-card [game]="item" ></csbc-game-card>
    </div>
  </div>
</div>
