import { Component, OnInit } from '@angular/core';

@Component({
    // moduleId: module.id,
    selector: 'csbc-player-list',
    templateUrl: './player-list.component.html',
    styleUrls: ['./player.component.scss']
})
export class PlayerListComponent implements OnInit {
    constructor() { }

    ngOnInit() { }
}