import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-csbc-photos',
  templateUrl: './csbc-photos.component.html',
  styleUrls: ['./csbc-photos.component.css']
})
export class CsbcPhotosComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
