import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'csbc-admin-division-list',
  templateUrl: './admin-division-list.component.html',
  styleUrls: [
    './admin-division-list.component.scss',
    '../../admin.component.scss'
  ]
})
export class AdminDivisionListComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
