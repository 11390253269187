import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'csbc-admin-game-detail',
  templateUrl: './admin-game-detail.component.html',
  styleUrls: ['./admin-game-detail.component.css']
})
export class AdminGameDetailComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
