<h1 mat-dialog-title>Scores</h1>
<div mat-dialog-content>
  <form
    class="dialog-form"
    [formGroup]="gameScoreForm"
    (ngSubmit)="onSubmitClick()"
  >
    <div>
      <div class="teamName">{{game.homeTeamName}}: </div>
      <mat-form-field>
        <input matInput type="number" formControlName="homeTeamScore" placeholder="" />
      </mat-form-field>
    </div>

    <div>
      <div class="teamName">{{game.visitingTeamName}}</div>
      <mat-form-field>
        <input matInput type="number" formControlName="visitorTeamScore" placeholder="" />
      </mat-form-field>
    </div>
  </form>
</div>
<div class="message">Scores and standings will be updated after refreshing the application</div>
<div mat-dialog-actions>
  <button
    mat-raised-button
    mat-dialog-close
    (click)="onSubmitClick()"
    cdkFocusInitial
  >
    Ok
  </button>
  <button mat-raised-button (click)="onCancelClick()">Cancel</button>
</div>
