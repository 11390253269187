<mat-card>
  <mat-card-header>
    <mat-card-title>{{ info.title }}</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div class="sub-title">{{ info.subTitle }}</div>
    <div class="text-center">
      <span>{{ info.body }}</span>
    </div>
    <div class="infoLocation" [hidden] = "hideLocationAndDateTime()">
      <div class="text-center" [hidden]="!info.location">
        Location: {{ info.location }}
      </div>
      <div class="text-center" [hidden]="!info.dateAndTime">
        Date/Time: {{ info.dateAndTime }}
      </div>
    </div>
  </mat-card-content>
</mat-card>
