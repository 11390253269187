<div
  fxLayout="row wrap"
  fxLayoutAlign="start"
  fxLayoutGap="10px"
  style="margin-top:20px;"
>
  <div fxFlex="100" style="margin-bottom:12px;">
    <csbc-admin-season-list
    ></csbc-admin-season-list>
  </div>
  <!-- <div fxFlex="65" style="margin-bottom:12px;">Detail</div> -->
</div>
