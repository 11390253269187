<form [formGroup]="criteriaForm" class="filter-wrapper">
  <div class="filter-divisions">
    <mat-form-field>
      <mat-select placeholder="Divisions" formControlName="divisions">
        <mat-option
          *ngFor="let division of divisions"
          [value]="division"
        >
          {{ division.divisionDescription }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-select placeholder="Teams" formControlName="teams">
        <mat-option *ngFor="let team of teams" [value]="team">
          {{ team.teamName }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-button-toggle-group name="gameView" aria-label="Schedule View">
      <mat-button-toggle mat-icon-button routerLink="list" value="list"
      matTooltip = "Show in grid view"
      [matTooltipClass]="'tooltip'">
        <mat-icon>list</mat-icon>
      </mat-button-toggle>
      <mat-button-toggle mat-icon-button routerLink="card" value="card"
      matTooltip = "Show in card view"
      [matTooltipClass]="'tooltip'">
        <mat-icon>apps</mat-icon>
      </mat-button-toggle>
    </mat-button-toggle-group>
  </div>
</form>
