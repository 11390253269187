<form fxFlex [formGroup]="selectForm">
  <mat-form-field>
    <mat-label>Select Season</mat-label>
    <mat-select formControlName="description" [(ngModel)]="selected">
      <mat-option
      *ngFor="let season of seasons$ | async"
      [value]="season"
      (click)="selectedSeason(season)"
    >
      {{ season.description }}
    </mat-option>
    </mat-select>
  </mat-form-field>
</form>
