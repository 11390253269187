
<form formName="gameForm">
  <div id="panelGames" flexLayout="row">
    <div>
      <div class="table-wrapper">
        <table mat-table id="grdSchedule" [dataSource]="dataSource">
          <ng-container matColumnDef="gameDate">
            <th mat-header-cell *matHeaderCellDef>Date</th>
            <td mat-cell *matCellDef="let game" class="date">
              {{ game.gameDate | date: 'M/d' }}
            </td>
          </ng-container>
          ><ng-container matColumnDef="homeTeamName">
            <th mat-header-cell *matHeaderCellDef>Home</th>
            <td mat-cell *matCellDef="let game" class="teamName">
              {{ game.homeTeamName }}
            </td>
          </ng-container>
          <ng-container matColumnDef="visitingTeamName">
            <th mat-header-cell *matHeaderCellDef>Visitor</th>
            <td mat-cell *matCellDef="let game" class="teamName">
              {{ game.visitingTeamName }}
            </td>
          </ng-container>
          <ng-container matColumnDef="homeTeamScore">
            <th mat-header-cell *matHeaderCellDef>Home</th>
            <td mat-cell *matCellDef="let game" class="teamName">
              {{ game.homeTeamScore }}
            </td>
          </ng-container>
          <ng-container matColumnDef="visitingTeamScore">
            <th mat-header-cell *matHeaderCellDef>Visitor</th>
            <td mat-cell *matCellDef="let game" class="teamName">
              {{ game.visitingTeamScore }}
            </td>
          </ng-container>
          <ng-container matColumnDef="actions" *ngIf="canEdit">
            <th mat-header-cell *matHeaderCellDef>Actions</th>
            <td mat-cell *matCellDef="let game">
              <button class="edit" *ngIf="canEdit" mat-icon-button><mat-icon>edit</mat-icon></button>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns" ></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>
    </div>
  </div>
</form>
