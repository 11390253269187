import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'sponsor-info',
  templateUrl: './sponsor-info.component.html',
  styleUrls: ['./sponsor-info.component.css']
})
export class SponsorInfoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
