<div class="row PageTop">
    <div class="col-sm-10 col-sm-offset-1 col-xs-12">
        <div class="panel panel-default" >
            <div class="panel-heading">
                <div class="panel-title">{{title}}</div>
            </div>
            <div class="panel-body">
                <table class="table table-hover table-responsive table-condensed">

                    <tr *ngFor = "let contact of directorList$ | async">
                        <td >{{contact.title | titlecase }}</td>
                        <td >{{contact.firstName + " " + contact.lastName | titlecase }}</td>
                        <td >{{contact.cellPhone}}</td>
                        <td >{{contact.email}}</td>
                    </tr>

                </table>

            </div>
        </div>
    </div>
</div>
