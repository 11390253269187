import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'draft-info',
  templateUrl: './draft-info.component.html',
  styleUrls: ['./draft-info.component.css']
})
export class DraftInfoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
