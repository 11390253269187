<h1 mat-dialog-title>Login</h1>
<div mat-dialog-content>
  <form class="login-form" [formGroup]="loginForm" (ngSubmit)="onSubmitClick()">
    <!-- <mat-error *ngIf="!isFormValid" class="error"
      >User Name or password is not valid
    </mat-error> -->
    <mat-form-field>
      <input matInput formControlName="userName" placeholder="User Name" />
      <!-- <mat-error *ngIf="userName.errors">login name is required</mat-error> -->
    </mat-form-field>

    <mat-form-field>
      <input matInput type="password" formControlName="password" placeholder="Password" />
      <!-- <mat-error *ngIf="password.invalid" class="error"
        >Password is required!</mat-error
      > -->
    </mat-form-field>
  </form>
</div>
<div mat-dialog-actions>
  <button
    mat-raised-button
    mat-dialog-close
    (click)="onSubmitClick()"
    cdkFocusInitial
    color="primary"
    style="margin-right:10px;"
  >
    Ok
  </button>

  <button mat-raised-button color="primary" (click)="onCancelClick()">
    Cancel
  </button>
</div>
