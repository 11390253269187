<mat-nav-list >
    <a mat-list-item (click)="onSidenavClose()" >
    <mat-icon>home</mat-icon><span class="nav-caption">Home</span>
    </a>
    <a mat-list-item routerLink="/games" >Games</a>
    <a  mat-list-item routerLink="/photos">Photos</a>
    <a  mat-list-item routerLink="/contacts">Contacts</a>
    <a  mat-list-item routerLink="/clubDocs">Club Docs</a>
    <a  mat-list-item *ngIf="showAdminMenu" routerLink="/dashboard">Dashboard</a>
    <a  mat-list-item *ngIf="showAdminMenu" routerLink="/admin" >Admin</a>
</mat-nav-list>
