<p>
  import-schedule works!
</p>
<mat-selection-list #shoes>
  <!-- <mat-list-option *ngFor="let shoe of typesOfShoes">
    {{shoe}}
  </mat-list-option> -->
</mat-selection-list>

<p>
  Options selected: {{shoes.selectedOptions.selected.length}}
</p>