<div>
  <!-- <div fxFlex="100" style="margin-bottom:12px; padding-left:20px;">
    <csbc-game-filter [currentDivision]="selectedDivisionId$ | async">
    </csbc-game-filter>
  </div> -->
  <div  >
    <div>
      <csbc-standings [standings]="standings$ | async"></csbc-standings>
    </div>
  </div>
</div>

