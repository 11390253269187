import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'sponsor-list',
  templateUrl: './sponsor-list.component.html',
  styleUrls: ['./sponsor-list.component.css']
})
export class SponsorListComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
