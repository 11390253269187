import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'season-setup',
  templateUrl: './season-setup.component.html',
  styleUrls: ['./season-setup.component.scss', '../../admin.component.scss']
})
export class SeasonSetupComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
