<form formName="gameForm">
  <div class="gamedate">{{ gameDate | date: "EEEE, MMMM d" }}</div>
  <div id="panelGames" flexLayout="row">
    <div>
      <div class="table-wrapper">
        <table mat-table id="grdSchedule" [dataSource]="games">
          <!-- <ng-container matColumnDef="gameDate">
            <th mat-header-cell *matHeaderCellDef>Date</th>
            <td mat-cell *matCellDef="let game" class="teamName">
              {{ game.gameDate | date: 'shortDate' }}
            </td>
          </ng-container> -->
          <ng-container matColumnDef="gameTime">
            <th mat-header-cell *matHeaderCellDef>Time</th>
            <td mat-cell *matCellDef="let game" class="time">
              {{ game.gameTime | date: "shortTime" }}
            </td>
          </ng-container>

          <ng-container matColumnDef="visitingTeam" class="col-1">
            <th mat-header-cell *matHeaderCellDef>Visitor</th>
            <td mat-cell *matCellDef="let game" class="teamName">
              {{ game.visitingTeamName }}
            </td>
          </ng-container>
          <div class="col-2">
            <ng-container matColumnDef="homeTeam" class="col-1">
              <th mat-header-cell *matHeaderCellDef>Home</th>
              <td mat-cell *matCellDef="let game" class="teamName">
                {{ game.homeTeamName }}
              </td>
            </ng-container>
          </div>
          <ng-container matColumnDef="locationName">
            <th mat-header-cell *matHeaderCellDef>Location</th>
            <td mat-cell *matCellDef="let game" class="location">
              {{ game.locationName }}
            </td>
          </ng-container>
          <ng-container matColumnDef="homeTeamScore">
            <th mat-header-cell *matHeaderCellDef>Home Score</th>
            <td mat-cell *matCellDef="let game" class="teamName">
              {{ game.homeTeamScore }}
            </td>
          </ng-container>
          <ng-container matColumnDef="visitingTeamScore">
            <th mat-header-cell *matHeaderCellDef>Visitor Score</th>
            <td mat-cell *matCellDef="let game" class="teamName">
              {{ game.visitingTeamScore }}
            </td>
          </ng-container>
          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>Actions</th>
            <td mat-cell *matCellDef="let game">
              <button class="edit" (click)="editGame(game)" mat-icon-button>
                <mat-icon>edit</mat-icon>
              </button>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>
    </div>
  </div>
</form>
