<p>
  director-list works!
</p>
<div class="table-wrapper">
  <mat-table id="grdDirectors" [dataSource]="datasource" >
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>Name</th>
      <td mat-cell *matCellDef="let d">
        {{ d.name }}
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns;"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </mat-table>
</div>
