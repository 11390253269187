<div class="row">
    <div class="col-sm-3 col-xs-12">
        <csbc-season-select (setSeason) = "setSeason()" ></csbc-season-select>
    </div>
</div>
<div class="row" style="padding-top:16px;">
    <div class="col-sm-5">
      <csbc-division-list  ></csbc-division-list>
    </div>
</div>
