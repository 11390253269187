<div class="row">
    <div class="col-sm-8">
        <div id="panelSeason" class="row">
            <div class="panel panel-primary">
                <div>
                    <table id="grdSeason" class="table table-condensed">
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Team Name</th>
                                <th>Color</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let team of teams" (click)="onSelect(team)">
                                <td>{{team.teamId}}</td>
                                <td>{{team.name}}</td>
                                <td>{{team.teamColorId}}</td>
                            </tr>
                        </tbody>
                    </table>

                </div>
            </div>
        </div>
    </div>

    <!--<div class="col-sm-8">
        <div *ngIf="selectedDivision">
            <csbc-season-detail [season]="selectedDivision"></csbc-season-detail>
        </div>
    </div>-->
</div>
