<div class="flex-container" fxLayout="row" fxLayoutGap="10px" style="margin-top:20px;">
  <div fxFlex="25" fxFlex.lt-sm="40" style="margin-bottom:12px;">
    Division
  </div>
  <div fxFlex="75" >
    Teams
  </div >
  <div fxFlex="50">Players</div>
  <div fxFlex="50"></div>
</div>

