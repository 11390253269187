<div>
  <label>Division</label>
  <select
    name="division"
    class="form-control"
    [(ngModel)]="selectedDivision"
    
  >
    <option *ngFor="let division of divisions$ | async" [ngValue]="division">
      {{ division.divisionDescription }}
    </option>
  </select>
</div>

<!-- (click)="onClick(selectedDivision)" -->
