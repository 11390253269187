<h2>{{title}}</h2>
<form formName="gameForm">
  <div id="panelGames" flexLayout="row">
    <div>
      <div class="table-wrapper">
        <mat-table #table id="grdSchedule" [dataSource]="dataSource">
          <ng-container matColumnDef="gameDate">
            <th mat-header-cell *matHeaderCellDef>Date</th>
            <td mat-cell *matCellDef="let game" class="date">
              {{ game.gameDate | date: 'M/d' }}
            </td>
          </ng-container>
          <ng-container matColumnDef="gameTime">
            <th mat-header-cell *matHeaderCellDef>Time</th>
            <td mat-cell *matCellDef="let game" class="time">
              {{ game.gameTime | date: 'shortTime' }}
            </td>
          </ng-container>
          <ng-container matColumnDef="locationName">
            <th mat-header-cell *matHeaderCellDef>Location</th>
            <td mat-cell *matCellDef="let game" class="location">
              {{ game.locationName }}
            </td> </ng-container
          ><ng-container matColumnDef="homeTeamName">
            <th mat-header-cell *matHeaderCellDef>Home</th>
            <td mat-cell *matCellDef="let game" class="teamName">
              {{ game.homeTeamName }}
            </td>
          </ng-container>
          <ng-container matColumnDef="visitingTeamName">
            <th mat-header-cell *matHeaderCellDef>Visitor</th>
            <td mat-cell *matCellDef="let game" class="teamName">
              {{ game.visitingTeamName }}
            </td>
          </ng-container>
          <ng-container matColumnDef="homeTeamScore">
            <th mat-header-cell *matHeaderCellDef>Home Score</th>
            <td mat-cell *matCellDef="let game" class="teamName">
              {{ game.homeTeamScore }}
            </td>
          </ng-container>
          <ng-container matColumnDef="visitingTeamScore">
            <th mat-header-cell *matHeaderCellDef>Visitor Score</th>
            <td mat-cell *matCellDef="let game" class="teamName">
              {{ game.visitingTeamScore }}
            </td>
          </ng-container>
          <ng-container matColumnDef="actions" *ngIf="canEdit">
            <th mat-header-cell *matHeaderCellDef>Actions</th>
            <td mat-cell *matCellDef="let game">
              <button class="edit" *ngIf="canEdit" (click) = "editGame(game)" mat-icon-button><mat-icon>edit</mat-icon></button>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns" ></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </mat-table>
      </div>
    </div>
  </div>
</form>
