import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'drafted-players',
  templateUrl: './drafted-players.component.html',
  styleUrls: ['./drafted-players.component.css']
})
export class DraftedPlayersComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
