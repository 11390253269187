import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'sponsor-shell',
  templateUrl: './sponsor-shell.component.html',
  styleUrls: ['./sponsor-shell.component.css']
})
export class SponsorShellComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
