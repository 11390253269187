import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'season-registrations',
  templateUrl: './season-registrations.component.html',
  styleUrls: ['./season-registrations.component.css']
})
export class SeasonRegistrationsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
