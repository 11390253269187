<div
  fxLayout="row wrap"
  fxLayoutAlign="start"
  fxLayoutGap="10px"
  style="margin-top:20px;"
>
  <div fxFlex="100" style="margin-bottom:12px;">
    <season-select></season-select>
  </div>

  <div fxFlex="100" style="margin-bottom:12px;">
    <csbc-division-list></csbc-division-list>
  </div>
</div>
