<!-- <div class="row">
  <aside class="col-sm-2">
  </aside>
  <div class="col-sm-10">
    <div class="row">
      <h3 class="pull-right">ADMIN</h3>

      <router-outlet></router-outlet>
    </div>
  </div>
</div> -->
<div>Admin</div>