<!-- <div class="row">
  <div class="col-sm-3 col-xs-6 col-xss-12">
    <div class="card" routerLink="/admin/season">
      <div class="heading">Current Season</div>
      <div class="body" *ngIf="currentSeason">
        {{ currentSeason.description }}
      </div>
    </div>
  </div>

  <div class="col-sm-3 col-xs-6 col-xss-12">
    <div class="card" routerLink="/admin/division">
      <div class="heading">Divisions</div>
      <div *ngFor="let division of divisions">
        {{ division.div_Desc }}
      </div>
    </div>
  </div>
  <div class="col-sm-3 col-xs-6 col-xss-12">
    <div class="card" routerLink="/admin/player">
      <div class="heading">Players</div>
      <div class="body">40</div>
    </div>
  </div>
  <div> -->
<div fxLayout="row wrap" fxLayoutAlign="start" fxLayoutGap="10px">
  <div fxFlex>
    <mat-card class="example-card">
      <mat-card-header>
        <mat-card-title>Current Season:</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div class="body" *ngIf="currentSeason">
          {{ currentSeason.description }}
        </div>
      </mat-card-content>
    </mat-card>
  </div>
  <div fxFlex>
    <mat-card class="example-card">
      <mat-card-header>
        <!-- <div mat-card-avatar class="example-header-image"></div> -->
        <mat-card-title>Divisions</mat-card-title>
        <mat-card-subtitle class="heading">No.: {{ divisionCount }}</mat-card-subtitle>

    </mat-card-header>
      <mat-card-content>
        <mat-list role="list">
            <mat-list-item role="listitem" *ngFor="let division of divisions"> {{ division.divisionDescription }}</mat-list-item>
          </mat-list>
      </mat-card-content>
    </mat-card>
  </div>

  <div>
    <mat-card class="example-card">
      <mat-card-header>
        <!-- <div mat-card-avatar class="example-header-image"></div> -->
        <mat-card-title>Teams</mat-card-title>
        <!-- <mat-card-subtitle>Dog Breed</mat-card-subtitle>-->
      </mat-card-header>
      <mat-card-content>
        <!-- <div class="heading">Current Season</div> -->
        <div class="body">
          {{ teamCount }}
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
<!-- </div> -->
<!-- </div> -->
